import { HomepageExperiments } from './experiments'
import { ctaStripStep } from './steps/ctaStrip'
import { dynamicHeroStep } from './steps/dynamicHeroStep'
import { removeRecommendationsForCustomerStep } from './steps/recommendationsforCustomerStep'
import { removeExperimentModulesStep } from './steps/removeExperimentModulesStep'

const defaultSteps = [dynamicHeroStep, removeExperimentModulesStep]

const controlLoggedOutSteps = [
  ...defaultSteps,
  removeRecommendationsForCustomerStep,
]

const controlLoggedInSteps = [...defaultSteps]

export const getPipelineSteps = ({
  loggedIn,
}: {
  loggedIn: boolean | null
  region: string
  experiments: { [key in HomepageExperiments]: string } | undefined
}) => {
  return loggedIn
    ? [...controlLoggedInSteps, ctaStripStep]
    : [...controlLoggedOutSteps, ctaStripStep]
}
