import React, { FC, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ProductList } from '../../components/ProductList/ProductList'
import { TabCarousel } from '../../components/TabCarousel/TabCarousel'
import { ModuleHeader, ModuleSection } from '../../components/ModuleContainer'
import { useFindLocaleText } from '../../text-localisation'
import { ModuleTabbedProductListsFragment } from './__generated__/fragment'
import { Module, ModuleTracking } from '../types'

type TabbedProductListsModuleProps = {
  excludeRudeCards?: boolean
} & ModuleTabbedProductListsFragment &
  ModuleTracking

export const TabbedProductListsModule: FC<
  Module<TabbedProductListsModuleProps>
> = ({
  module: { productLists, tracking, title, excludeRudeCards },
  context: { backgroundColor },
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const [productListMarkerRef, aproachingProductList] = useInView({
    threshold: 0,
    rootMargin: '100px',
    triggerOnce: true,
  })

  const [isInView, setIsInView] = useState(false)

  const localiseText = useFindLocaleText()

  const { pageLocation, moduleProductListIndex, moduleProductListsLength } =
    tracking

  const trackingDataProps = {
    pageLocation,
    moduleIndex: moduleProductListIndex,
    modulesLength: moduleProductListsLength,
    moduleTitle: title,
  }

  const processedProductLists = productLists.map(pl => ({
    ...pl,
    type: pl.__typename,
    promotionId:
      pl.promotionId === null
        ? /* istanbul ignore next */ undefined
        : pl.promotionId,
  }))

  useEffect(() => {
    if (!isInView && aproachingProductList) {
      setIsInView(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aproachingProductList])

  return (
    <ModuleSection
      ref={productListMarkerRef}
      data-testid="module-tabbed-product-lists"
      withContainer
      fullWidthOnMobile
      padding={{ py: { xs: 6, lg: 8 } }}
      backgroundColor={backgroundColor}
    >
      {isInView && (
        <>
          <ModuleHeader title={title} />
          <TabCarousel
            productLists={processedProductLists}
            trackingDataProps={trackingDataProps}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            ariaLabel={localiseText('find.tab_carousel_title', {
              moduleTitle: title,
            })}
          />
          <ProductList
            {...processedProductLists[selectedTabIndex]}
            trackingDataProps={trackingDataProps}
            backgroundColor={backgroundColor}
            tabbedCarousel
            excludeRudeCards={excludeRudeCards}
          />
        </>
      )}
    </ModuleSection>
  )
}
