import React, { FC } from 'react'
import {
  CarouselState,
  CarouselControls,
  CarouselPreviousButton,
  CarouselNextButton,
  UnstyledCarouselRoot,
  UnstyledCarouselScrollArea,
  CarouselItem,
  Chip,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useIsMobile } from '../../utils'
import { ModuleSearchResults } from '../../types'
import { selectContentGAEvent } from '../../analytics/commonGAEvents'

const StyledCarouselRoot = styled(UnstyledCarouselRoot)`
  ${s({
    mb: { xs: 6, lg: 8 },
  })}
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)`
  ${s({
    px: { xs: 6, lg: 0 },
    gap: { xs: 8, md: 16 },
    justifyContent: { xs: 'initial', md: 'center' },
  })}
`

type TabCarouselProps = {
  productLists: ModuleSearchResults[]
  trackingDataProps: {
    pageLocation: string
    moduleTitle: string
    moduleIndex: number
    modulesLength: number
  }
  selectedTabIndex: number
  setSelectedTabIndex: (index: number) => void
  ariaLabel: string
}

export const TabCarousel: FC<TabCarouselProps> = ({
  productLists,
  trackingDataProps,
  selectedTabIndex,
  setSelectedTabIndex,
  ariaLabel,
}) => {
  const showScrollButtons = !useIsMobile()

  const handleTabClick = (index: number) => {
    setSelectedTabIndex(index)
    const trackingData = { ...trackingDataProps, tabbedCarousel: true }

    const selectContentEvent = selectContentGAEvent({
      type: productLists[index].title.toLocaleLowerCase(),
      context: 'tab',
      action: 'select content',
      category: trackingDataProps.pageLocation,
      ...trackingData,
    })

    trackGAEvent(selectContentEvent)
  }

  return (
    <CarouselState>
      <StyledCarouselRoot aria-label={ariaLabel}>
        {showScrollButtons && (
          <CarouselControls>
            <CarouselPreviousButton />
            <CarouselNextButton />
          </CarouselControls>
        )}
        <StyledCarouselScrollArea>
          {productLists.map(({ title: tabTitle }, index) => (
            <CarouselItem key={tabTitle} tabIndex={-1}>
              <Chip
                selected={selectedTabIndex === index}
                onClick={() => handleTabClick(index)}
              >
                {tabTitle}
              </Chip>
            </CarouselItem>
          ))}
        </StyledCarouselScrollArea>
      </StyledCarouselRoot>
    </CarouselState>
  )
}
