import { gql } from '@moonpig/web-core-graphql'

export const GetProductsProductListGQL = gql`
  query GetProductsProductList(
    $searchTerm: String!
    $department: [DepartmentsEnum!]
    $filters: CriteriaInput!
    $customerId: String
    $experimentValues: String
    $nbaAlgorithm: String
    $platform: Platform
  ) {
    productSearch(
      limit: 20
      searchTerm: $searchTerm
      department: $department
      filters: $filters
      experimentValues: $experimentValues
      nbaAlgorithm: $nbaAlgorithm
      customerId: $customerId
      platform: $platform
    ) {
      products {
        title
        description
        slug
        dependencies
        clickRankDocumentCount
        category {
          slug
          name
          id
          department
        }
        customisable
        isLandscape
        rating {
          count
          score
        }
        id
        masterVariant {
          key
          title
          images {
            url
          }
          price {
            centAmount
            currencyCode
            fractionDigits
          }
          fullPrice {
            centAmount
            currencyCode
            fractionDigits
          }
          discountedPercentage
          masterImage {
            url
          }
          inStock
          minimumQuantity
          sku
          bundles {
            description
            price {
              currencyCode
              centAmount
              fractionDigits
            }
            discountPercentage
            size
          }
          capabilities {
            video
          }
        }
        variants {
          key
          title
          dimensions {
            description
          }
          subtitle
          price {
            currencyCode
            centAmount
            fractionDigits
          }
          fullPrice {
            currencyCode
            centAmount
            fractionDigits
          }
          discountedPercentage
          inStock
          minimumQuantity
          sku
          bundles {
            description
            price {
              currencyCode
              centAmount
              fractionDigits
            }
            discountPercentage
            size
          }
          capabilities {
            video
          }
        }
        hasAugmentedReality
        primaryProductPill {
          displayLabel
          displayVariant
        }
        productPills {
          displayLabel
          displayVariant
        }
        isSponsored
      }
    }
  }
`
