import React, { FC, useEffect, useState } from 'react'

import { useInView } from 'react-intersection-observer'
import { ModuleSearchResults } from '../../types'
import { ProductList } from '../../components/ProductList/ProductList'
import { ModuleHeader } from '../../components/ModuleContainer'

type ProductListModuleProps = {
  trackingDataProps: {
    pageLocation: string
    moduleIndex: number
    modulesLength: number
    moduleTitle: string
  }
  backgroundColor?: string
  excludeRudeCards?: boolean
} & ModuleSearchResults

export const ProductListModule: FC<ProductListModuleProps> = module => {
  const [productListMarkerRef, aproachingProductList] = useInView({
    threshold: 0,
    rootMargin: '100px',
    triggerOnce: true,
  })
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    if (!isInView && aproachingProductList) {
      setIsInView(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aproachingProductList])

  return (
    <div ref={productListMarkerRef} data-testid="web-browse-product-list">
      {(isInView || module.trackingDataProps.moduleIndex === 1) && (
        <>
          <ModuleHeader title={module.title} subtitle={module.subtitle} />
          <ProductList {...module} />
        </>
      )}
    </div>
  )
}
