import { Box, Flex, SecondaryButton } from '@moonpig/launchpad-components'
import {
  AddedToBasketEvent,
  createViewItemListGAEvent,
  ProductTile,
  useHandleAddToBasket,
  useFavouritesState,
  useHandleFavourite,
  useBasket,
  getCardVariantIdsEnabled,
} from '@moonpig/web-shared-products'
import {
  createSeeMoreClickEvent,
  trackGAEvent,
  TrackingDataProvider,
} from '@moonpig/web-core-analytics'
import { useQuery } from '@moonpig/web-core-graphql'
import React, { FC, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { DepartmentsEnum, Platform } from '@moonpig/web-explore-types-graphql'
import { useStoreId } from '@moonpig/web-core-stores'
import { usePlatform } from '@moonpig/web-core-context'
import { setUIHCookie } from '@moonpig/web-shared-utils'
import { GetProductsProductListGQL } from '../../queries/getProductsProductList'
import {
  GetProductsProductListQuery,
  GetProductsProductListQueryVariables,
} from '../../queries/__generated__/GetProductsProductList'
import { useFindLocaleText } from '../../text-localisation'
import { ModuleSearchResults } from '../../types'
import { selectContentGAEvent } from '../../analytics/commonGAEvents'
import { transformFiltersToGroupedFilters } from '../../services/helpers/groupFilters'
import { Carousel } from '../Carousel'

type ProductListProps = {
  trackingDataProps: {
    pageLocation: string
    moduleIndex: number | string
    modulesLength: number | string
    moduleTitle: string
  }
  backgroundColor?: string
  tabbedCarousel?: boolean
  excludeRudeCards?: boolean
} & ModuleSearchResults

const getProductList = (
  data: GetProductsProductListQuery | undefined,
  previousData?: GetProductsProductListQuery | undefined,
) => {
  if (data) return data.productSearch.products
  // istanbul ignore next
  if (previousData) return previousData.productSearch.products
  return []
}

export const ProductList: FC<ProductListProps> = ({
  departments,
  facetDetails,
  keywords,
  promotionId,
  title,
  url,
  alternativeButton,
  tabbedCarousel,
  trackingDataProps,
  excludeRudeCards,
}) => {
  const storeId = useStoreId()
  const [_, { setLoading, setError }] = useBasket()
  const platform = usePlatform()

  const localiseText = useFindLocaleText()
  const soldOutMessage = localiseText('find.sold_out')
  const itsNotYouMessage = localiseText('find.its_not_you')
  const productListTrackingData = {
    title,
    position: trackingDataProps.moduleIndex,
    totalNumberOfLists: trackingDataProps.modulesLength,
    tabbedModuleTitle: trackingDataProps.moduleTitle,
  }
  const { pageLocation } = trackingDataProps
  const { handleAddToBasket, addingToBasket: loading } = useHandleAddToBasket({
    pageLocation,
    soldOutMessage,
    itsNotYouMessage,
    productList: productListTrackingData,
  })

  const [productListRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  let variants: number[] = []

  if (
    departments.includes('ALL_CARDS' as DepartmentsEnum) ||
    departments.includes('GREETING_CARDS' as DepartmentsEnum)
  ) {
    variants = getCardVariantIdsEnabled()
  }

  const criteriaFacets: {
    group: string
    key: string
    userApplied?: boolean
  }[] = facetDetails.map(f => {
    return {
      group: f.group || /* istanbul ignore next */ '',
      key: f.key || /* istanbul ignore next */ '',
      userApplied: f.userApplied,
    }
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename = '', ...filters } = {
    groupedFacets: transformFiltersToGroupedFilters(criteriaFacets),
  }

  const excludeRude =
    excludeRudeCards &&
    !departments.includes(DepartmentsEnum.ALL_GIFTS) &&
    !departments.includes(DepartmentsEnum.ALL_FLOWERS_AND_PLANTS) &&
    departments.includes(DepartmentsEnum.ALL_CARDS)

  const variables: GetProductsProductListQueryVariables = {
    searchTerm: keywords,
    department: departments as DepartmentsEnum[],
    filters: {
      ...{ ...filters },
      ...(variants && { variants }),
      ...(promotionId && { promotionId }),
      ...(excludeRude && { excludeRude }),
    },
    experimentValues: 'search-use-new-ia-index=true',
    platform: platform.isMobile ? Platform.MOBILE_WEB : Platform.WEB,
    nbaAlgorithm: undefined,
    customerId: undefined,
  }

  const { data, previousData } = useQuery<
    GetProductsProductListQuery,
    GetProductsProductListQueryVariables
  >(GetProductsProductListGQL, { variables })

  const addToBasketCallback = React.useCallback(
    async (
      e: AddedToBasketEvent,
      itemIndex: number,
      productsLength: number,
    ) => {
      const {
        product,
        variant,
        quantity,
        selectedAddon,
        buttonActionName,
        requiresEditingValue,
        trackPersonaliseEvent,
      } = e

      setUIHCookie({ product })
      try {
        await handleAddToBasket({
          quantity,
          product,
          variant,
          filters: criteriaFacets.map(f => {
            return {
              ...f,
              facetKey: f.key,
            }
          }),
          searchTerm: keywords,
          itemIndex,
          productsLength,
          buttonActionName,
          selectedAddon,
          requiresEditingValue,
          componentName: 'recommendations carousel',
          trackPersonaliseEvent,
        })
      } catch (error) {
        setError(true)
      }
    },
    [setError, handleAddToBasket, criteriaFacets, keywords],
  )

  const productList = useMemo(
    () => getProductList(data, previousData),
    [data, previousData],
  )

  const seeMoreTrackingData = {
    label: url,
  }

  const localiseFindText = useFindLocaleText()

  const getButtonText = () => {
    if (tabbedCarousel) return localiseFindText('find.view_category')
    if (alternativeButton) return localiseFindText('find.view_all')
    return localiseFindText('find.see_more')
  }

  let viewItemListGA4Event: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  } | null = null

  const carouselType = `${tabbedCarousel ? 'tabbed ' : ''}carousel`

  if (productList.length) {
    const modulesCount = `${trackingDataProps.moduleIndex}/${trackingDataProps.modulesLength}`
    const label = tabbedCarousel
      ? `${pageLocation} | ${trackingDataProps.moduleTitle} | ${carouselType} | ${modulesCount} | ${title} tab`
      : `${pageLocation} | ${title} | ${carouselType} | ${modulesCount}`
    viewItemListGA4Event = createViewItemListGAEvent({
      products: productList,
      label,
    })
  }

  const selectContentEvent = selectContentGAEvent({
    type: title,
    context: carouselType,
    action: 'select content',
    category: getButtonText(),
  })

  const { favourites } = useFavouritesState()
  const handleFavourite = useHandleFavourite({
    tracking: {
      totalCount: productList.length,
      pageLocation,
    },
    removeWithConfirmation: false,
  })

  useEffect(() => {
    if (inView) {
      if (viewItemListGA4Event) {
        trackGAEvent(viewItemListGA4Event)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList, inView])

  return (
    <div ref={productListRef} data-testid="web-browse-product-list-component">
      <Carousel data-testid="lp-components-carousel">
        {productList.map((product, index) => {
          const isCardProduct =
            product.category &&
            product.category.department ===
              ('GREETING_CARDS' as DepartmentsEnum)
          const trackingData = {
            pageType: pageLocation,
            productList: productListTrackingData,
            region: storeId,
            tile: {
              totalNumberOfProducts: productList.length,
              productIndex: index,
            },
          }
          return (
            <Box
              key={product.id}
              py={{ xs: 6, md: 7 }}
              pr={{ xs: index === productList.length - 1 ? 6 : 4, md: 5 }}
              pl={{ xs: index === 0 ? 6 : 4, md: 5 }}
              height="100%"
            >
              <Box
                width={{
                  xs: `${isCardProduct ? '36' : '60'}vw`,
                  md: '248px',
                }}
                height="100%"
              >
                <ProductTile
                  product={product}
                  onClick={() => {}}
                  onAddToBasket={e =>
                    addToBasketCallback(e, index, productList.length)
                  }
                  trackingData={trackingData}
                  tabbedCarousel
                  handleFavourite={handleFavourite}
                  isFavourited={Boolean(
                    favourites.find(
                      /* istanbul ignore next */ p => p?.id === product.id,
                    ),
                  )}
                />
              </Box>
            </Box>
          )
        })}
      </Carousel>
      <Flex justifyContent="space-around" pt={5}>
        <TrackingDataProvider
          eventData={createSeeMoreClickEvent(seeMoreTrackingData)}
        >
          {
            <SecondaryButton
              href={url}
              width="100%"
              maxWidth="192px"
              onClick={() => trackGAEvent(selectContentEvent)}
            >
              {getButtonText()}
            </SecondaryButton>
          }
        </TrackingDataProvider>
      </Flex>
    </div>
  )
}
