import { Box } from '@moonpig/launchpad-components'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { isModalLink } from '@moonpig/web-core-link'
import React, { FC } from 'react'
import { useTheme } from '@moonpig/launchpad-utils'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { ModuleErrorBoundary } from '@moonpig/web-shared-products'
import { CTAStripModule } from '../../modules/CTAStrip'
import { RemindersCTAStripModule } from '../../modules/RemindersCTAStrip'
import { DeliveryStripModule } from '../../modules/DeliveryStrip'
import { OfferStripModule } from '../../modules/OfferStrip'
import { ProductListModule } from '../../modules/ProductList'
import { VideoModule } from '../../modules/Video'
import { ContentModule } from '../../types'
import { TwoColumnLayout } from './TwoColumnLayout'
import { TwoColumnPlacardModule } from '../../modules/TwoColumnPlacard'
// import { VideoCardHeroModule } from '../../modules/VideoCardHero'
import {
  MODULE_SEARCH_RESULTS,
  MODULE_TABBED_PRODUCT_LISTS,
  MODULE_RECOMMENDATIONS_FOR_CUSTOMER,
} from '../Home/constants'
import { ModuleSection } from '../ModuleContainer'
import { mapModule } from '../../modules/map-modules'
import { ModuleTracking } from '../../modules/types'
import { createTracking } from '../../analytics/tracking'

type RenderModulesParams = {
  modules: TrackedModule[]
  alternatingBackground: boolean
  eCardsCannibalizationTestVariation?: string
  loggedIn: boolean
  theme: ThemeInterface
}

type TrackedModule = ContentModule & {
  trackingData: TrackingData
}

type LandingProps = {
  modules: ContentModule[]
}

type TrackingData = {
  moduleIndex: number
  moduleType: string
}

type TrackingDataExpanded = TrackingData & {
  productCarouselIndexOnPage: number
  numberOfProductCarouselsOnPage: number
}

const LIST_OF_MODULES_WITH_PRODUCT_LIST = [
  MODULE_SEARCH_RESULTS,
  MODULE_TABBED_PRODUCT_LISTS,
  MODULE_RECOMMENDATIONS_FOR_CUSTOMER,
]

const hasProductList = (module: ContentModule) => {
  return LIST_OF_MODULES_WITH_PRODUCT_LIST.includes(module.type)
}

const setProductListsTrackingData = (modules: TrackedModule[]) => {
  let productListIndexOnPage = 0
  const numberOfProductListsOnPage = modules.filter(hasProductList).length

  return modules.map(module => {
    if (hasProductList(module)) {
      productListIndexOnPage += 1

      return {
        ...module,
        tracking: {
          pageLocation: 'landing',
          moduleProductListIndex: productListIndexOnPage,
          moduleProductListsLength: numberOfProductListsOnPage,
        },
      }
    }

    return module
  })
}

const MODULE_HERO = 'ModuleHero'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
const partition = <T extends any>(
  arr: T[],
  predicate: (item: T) => boolean,
): [T[], T[]] =>
  arr.reduce<[T[], T[]]>(
    (acc, item) => {
      if (predicate(item)) {
        acc[0].push(item)
      } else {
        acc[1].push(item)
      }
      return acc
    },
    [[], []],
  )

const renderModule = ({
  module,
  key,
  darkBackground,
  trackingData,
}: {
  module: ContentModule
  key: number
  darkBackground: boolean
  trackingData: TrackingDataExpanded
  theme: ThemeInterface
}) => {
  const backgroundColor = darkBackground
    ? 'colorBackgroundSite'
    : 'colorBackground01'
  const page = 'landing'

  const defaultPadding = {
    pb: { xs: 8, lg: 8 },
    pt: { xs: 0, lg: 0 },
  }
  const largeSpacingPadding = {
    py: { xs: 8, lg: 10 },
  }

  const context = {
    key,
    index: key,
    backgroundColor,
    customStyles: defaultPadding,
    layout: page,
    tracking: createTracking({ page }),
  }

  switch (module.type) {
    case 'ModuleDeliveryStrip':
      return (
        <ModuleSection key={key} data-testid="module-delivery-strip">
          <DeliveryStripModule {...module} />
        </ModuleSection>
      )
    case 'ModuleCTAStrip':
      return (
        <ModuleSection key={key} data-data-testid="module-cta-Strip">
          {isModalLink(module.cta.url) ? (
            <RemindersCTAStripModule trackingData={trackingData} {...module} />
          ) : (
            <CTAStripModule trackingData={trackingData} {...module} />
          )}
        </ModuleSection>
      )
    case 'ModuleOfferStrip':
      return (
        <ModuleSection key={key} data-testid="module-offer-strip">
          <OfferStripModule {...module} />
        </ModuleSection>
      )
    case 'ModuleSearchResults':
      return (
        <ModuleSection
          {...context}
          data-testid="module-search-results"
          withContainer
          fullWidthOnMobile
          padding={largeSpacingPadding}
        >
          <ProductListModule
            {...module}
            trackingDataProps={{
              pageLocation: page,
              moduleIndex: trackingData.productCarouselIndexOnPage,
              modulesLength: trackingData.numberOfProductCarouselsOnPage,
              moduleTitle: module.title,
            }}
            backgroundColor={backgroundColor}
          />
        </ModuleSection>
      )
    case 'ModuleVideo':
      return (
        <ModuleSection
          {...context}
          withContainer
          data-testid="module-video"
          padding={largeSpacingPadding}
        >
          <VideoModule {...module} />
        </ModuleSection>
      )
    case 'ModuleTwoColumnPlacard':
      return (
        <ModuleSection
          {...context}
          data-testid="module-two-column-placard"
          withContainer
          padding={largeSpacingPadding}
        >
          <TwoColumnPlacardModule trackingData={trackingData} {...module} />
        </ModuleSection>
      )
    case 'ModuleTabbedProductLists':
      return mapModule(
        module as Extract<'ModuleTabbedProductLists', ContentModule> &
          ModuleTracking,
        context,
      )
    case 'ModuleBreadcrumbs':
    case 'ModuleHeading':
    case 'ModuleHero':
    case 'ModuleMissionButtons':
    case 'ModuleTiles':
    case 'ModuleInfo':
    case 'ModuleSEO':
    case 'ModulePlacards':
    case 'ModuleUspCarousel':
    case 'ModuleCard':
      return mapModule(module, context)
    default:
      return null
  }
}

const FIXED_BACKGROUND_MODULES = [
  'ModuleBreadcrumbs',
  'ModuleHeading',
  'ModuleHero',
  'ModuleMissionButtons',
  'ModuleRecipientsList',
  'ModuleDeliveryStrip',
  'ModuleCTAStrip',
  'ModuleProductsList',
  'ModuleUspCarousel',
  'ModuleCard',
]

const groupModules = (
  modules: TrackedModule[],
): {
  a: TrackedModule[]
  b: TrackedModule[]
  c: TrackedModule[]
  d: TrackedModule[]
} => {
  const heroModule = modules.find(module => module.type === MODULE_HERO)

  if (
    !heroModule ||
    (heroModule.type === MODULE_HERO && heroModule.heroVariant !== 'FullWidth')
  ) {
    return { a: modules, b: [], c: [], d: [] }
  }

  const heroModuleIndex = modules.indexOf(heroModule)
  const beforeHeroModules = modules.slice(0, heroModuleIndex)
  const afterHeroModules = modules.slice(heroModuleIndex + 1)
  const [besideHeroModules, belowHeroModules] = partition(
    afterHeroModules,
    module => module.type === 'ModulePlacards' && module.items.length === 1,
  )

  return {
    a: beforeHeroModules,
    b: [heroModule],
    c: besideHeroModules,
    d: belowHeroModules,
  }
}

const renderModules = ({
  modules,
  alternatingBackground,
  loggedIn,
  theme,
}: RenderModulesParams) => {
  let nextIndex = 0
  let numberOfProductCarouselsOnPage = 0
  let productCarouselIndexOnPage = 0

  const modulesWithProductListsTracking = setProductListsTrackingData(modules)

  return modulesWithProductListsTracking
    .reduce<TrackedModule[]>((accumulator, currentValue) => {
      return currentValue.type === 'ModuleRecommendationsForCustomer' &&
        !loggedIn
        ? accumulator
        : [...accumulator, currentValue]
    }, [])
    .map((module, index) => {
      let darkBackground = false

      if (!FIXED_BACKGROUND_MODULES.includes(module.type)) {
        // eslint-disable-next-line no-plusplus
        darkBackground = nextIndex++ % 2 === 0
      }

      if (hasProductList(module)) {
        const { tracking } = module as TrackedModule & ModuleTracking

        productCarouselIndexOnPage = tracking.moduleProductListIndex
        numberOfProductCarouselsOnPage = tracking.moduleProductListsLength
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <ModuleErrorBoundary key={index} type={module.type}>
          {renderModule({
            module,
            key: index,
            darkBackground: alternatingBackground && darkBackground,
            trackingData: {
              moduleIndex: index,
              moduleType: module.type,
              numberOfProductCarouselsOnPage,
              productCarouselIndexOnPage,
            },
            theme,
          })}
        </ModuleErrorBoundary>
      )
    })
}

const Landing: FC<LandingProps> = ({ modules }) => {
  const theme = useTheme()
  const { loggedIn } = useLoggedIn()

  const trackedModules = modules.map<TrackedModule>((module, index) => ({
    ...module,
    trackingData: {
      moduleIndex: index,
      moduleType: module.type,
    },
  }))

  const { a, b, c, d } = groupModules(trackedModules)
  const renderModulesParams = {
    alternatingBackground: false,
    loggedIn: loggedIn === true,
    theme,
  }

  return (
    <Box bgcolor="white">
      {b.length > 0 ? (
        <TwoColumnLayout
          a={renderModules({
            modules: a,
            ...renderModulesParams,
          })}
          b={renderModules({
            modules: b,
            ...renderModulesParams,
          })}
          c={renderModules({
            modules: c,
            ...renderModulesParams,
          })}
          d={renderModules({
            modules: d,
            ...renderModulesParams,
            alternatingBackground: true,
          })}
        />
      ) : (
        <>
          {renderModules({
            modules: a,
            ...renderModulesParams,
            alternatingBackground: true,
          })}
        </>
      )}
    </Box>
  )
}

export { Landing }
